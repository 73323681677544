<template>
<!-- top top-left top-right bottom-left bottom-right -->
<!-- block, edgeless, classic --->
  <div class="centerx cookie" :class="[cookiePosition, cookieTheme]"> 
    <vs-alert :style="{'background-color':cookiePalettePopupBackground}" :active.sync="isCookieConsentModel" color="warning">
      <div class="cookie-text" :style="{'color':cookiePalettePopupText}">
        <span>[Demo] </span>{{cookieCustomTextBanner}} 
        <a  
          class="text-warning" 
          :href="linkPolicy == 'vvPolicy' ? cookiePolicyLink : cookiePolicyLink" 
          target="_blank"
        >
          <b :style="{'color':cookiePalettePopupText}">{{cookieCustomTextPolicyLabel}}</b>
        </a>
      </div>
      <div class="cookie-btns"> 
        <a href="#" @click="$emit('closeModel',false)" >
          <span :style="{'color':cookiePaletteButtonText}">{{cookieCustomTextDismissButtonText}}</span>
        </a>
        <vs-button 
          @click="$emit('closeModel',false)" 
          color="warning" class="m-0" type="filled" :style="{'background-color'
          :cookiePaletteButtonBackground +'!important'}"
        >
          <span :style="{'color':cookiePaletteButtonText}">{{cookieCustomTextAcceptButtonText}}</span>
        </vs-button>
      </div>
    </vs-alert>
  </div>
</template>

<script>
import VxCard from "@/components/vx-card/VxCard";

export default {
  components: {
    VxCard,
  },
  data() {
    return {
      active1:true,
    };
  },
  props:{
    isCookieConsentModel:{
      required: true,
    },
    cookiePosition:{
      required: true,
      default: "bottom"
    },
    cookieTheme:{
      required: true,
      default: "block"
    },
    cookiePalettePopupBackground:{
      required: true,
      default: "#044D80"
    },
    cookiePalettePopupText:{
      required: true,
      default: "#FFFFFF"
    },
    cookiePaletteButtonBackground:{
      required: true,
      default: "#ECB940"
    },
    cookiePaletteButtonText:{
      required: true,
      default: "#FFFFFF"
    },
    linkPolicy:{
      required: false
    },
    cookieUseDefaultPolicy:{
      required: false
    },
    cookiePolicyLink:{
      required: false,
    },
    cookieCustomTextBanner:{
      required: true,
      default: "This website uses cookies to ensure you get the best experience on our website."
    },
    cookieCustomTextPolicyLabel:{
      required: true,
      default: "Learn more"
    },
    cookieCustomTextAcceptButtonText:{
      required: true,
      default: "Allow cookies"
    },
    cookieCustomTextDismissButtonText:{
      required: true,
      default: "Decline"
    },
  },
};
</script>

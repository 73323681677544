<template>
<div>
    <div>
        <div class="vx-row">
            <div class="vx-col w-full mt-8">
                <vx-card class="install-card-tab">
                    <div class="card-title"><h2>Installation</h2></div>
                    <b-tabs v-model="tabIndex">
                        <b-tab title="Instructions">
                            <b-card-text>
                                <form @submit.prevent="checkValidation()" autocomplete="off" data-vv-scope="senddetails">
                                    <div class="tab-text">
                                        <div class="update-info-form">
                                            <div class="vx-row mb-6" id="v-step-0">
                                                <div class="vx-col w-full">
                                                    <p class="mb-4 small-tag"> {{ LabelConstant.infoLabelInstallation }}</p>
                                                    <div class="textarea-main">
                                                        <vs-textarea v-model="installationCode" class="form-textarea mb-4" :readonly="true" id="myInput" />
                                                        <vs-button :title="LabelConstant.buttonTitleCopyToClipboard" type="filled" icon-pack="feather" icon="icon-copy" class="mr-2 float-left bg-actionbutton" @click.prevent="copyText()"></vs-button>
                                                    </div>
                                                </div>
                                            </div>
                                            <template>
                                                <div class="vx-row">
                                                    <div class="mb-6">
                                                        <div class="vx-col w-full pl-4 mb-4" v-if="plusEmployeeVisible">
                                                            <vs-checkbox v-model="plusEmployeeEnabled" @input="changePlusEmployee()">Enable + Employee</vs-checkbox>
                                                        </div>
                                                        <div class="vx-col w-full pl-4 mb-4 d-flex" v-if="cookieConsentVisible">
                                                        <!-- <div class="vx-col w-full pl-4 mb-4 d-flex" v-if="cookieConsentVisible && !vendastaIsPartner"> -->
                                                            <vs-checkbox v-model="cookieConsentEnabled" @input="changeCookieConsent()" :disabled="cookieConsentReadOnly || isUntrustedReseller">Add Cookie Consent Banner to Script</vs-checkbox>
                                                            <a :class="[!isUntrustedReseller ? 'cursor-not-allowed':'cursor-pointer']" v-if="cookieConsentEnabled" @click="tabIndex=1" >(Configure your custom cookie consent banner)</a>
                                                            <!-- <a :class="[cookieConsentReadOnly && !isUntrustedReseller ? 'cursor-not-allowed':'cursor-pointer']" v-if="cookieConsentEnabled" @click="tabIndex=1" >(Configure your custom cookie consent banner)</a> -->
                                                        </div>
                                                        <!-- <div class="vx-col w-full pl-4 mb-4 d-flex" v-if="cookieConsentVisible && vendastaIsPartner">
                                                            <vs-checkbox v-model="cookieConsentEnabled" @input="changeCookieConsent()">Add Cookie Consent Banner to Script</vs-checkbox>
                                                            <a class="cursor-pointer" @click="tabIndex=1" >(Configure your custom cookie consent banner)</a>
                                                        </div> -->
                                                        <div class="mb-4 mx-6 pl-8" v-if="cookieConsentVisible && (cookieConsentReadOnly && !isUntrustedReseller) && requestToDisableConsent">
                                                        <!-- <div class="mb-4 mx-6 pl-8" v-if="cookieConsentVisible && (cookieConsentReadOnly && !isUntrustedReseller)"> -->
                                                        <!-- <div class="mb-4 mx-6 pl-8" v-if="cookieConsentVisible && !isUntrustedReseller"> -->
                                                            <p  class="text-danger">You have requested to disable Cookie Consent on your script. An email has been sent to {{userEmail}} with instructions to finalize this request.</p>
                                                            <a class="cursor-pointer mr-8" @click="resendEmail()">Resend Email</a>
                                                            <a class="cursor-pointer ml-8" @click="cancelRequest()">Cancel Request</a>
                                                        </div>
                                                    </div>
                                                    <div class="vx-col w-full">
                                                        <p class="mb-4 small-tag"><b>{{ LabelConstant.headingLabelSendInstallationInstructions }}</b></p>
                                                    </div>
                                                    <div class="vx-col w-full mb-6">
                                                        <vs-input class="w-full" icon-pack="feather" icon="icon-mail" icon-no-border :label="LabelConstant.inputLabelEnterEmail" v-model="enterEmail" name="enterEmail" v-validate="'required|email'" />
                                                        <span class="text-danger text-sm">{{ errors.first('senddetails.enterEmail') }}</span>
                                                    </div>
                                                    <div class="vx-col w-full">
                                                        <span class="vs-input--label">{{ LabelConstant.inputLabelMessageToRecipient }}</span>
                                                        <vs-textarea v-model="txtMsg" class="form-textarea mb-4 vs-textarea--input" />
                                                    </div>
                                                </div>
                                                <div class="vx-row">
                                                    <div class="vx-col w-full mt-6">
                                                        <vs-button type="filled" class="mr-4 mb-2" color="primary" button="submit">{{ LabelConstant.buttonLabelSendInstallationInstructions }}</vs-button>
                                                        <vs-button color="grey" type="border" class="mb-2 grey-btn" @click="resetFirstTab()">{{ LabelConstant.buttonLabelReset }}</vs-button>
                                                    </div>
                                                </div>
                                            </template>
                                        </div>
                                    </div>
                                </form>
                            </b-card-text>
                        </b-tab>
                        <b-tab title="Shopping Cart" v-if="isShoppingCartShow">
                            <b-card-text>
                                <form autocomplete="off" data-vv-scope="senddetails">
                                    <div class="tab-text">
                                        <div class="update-info-form">
                                            <template>
                                                <div class="vx-row">
                                                    <div class="vx-col w-full mt-5 mb-6">
                                                        <h4>Shopping Cart Settings</h4>
                                                        <div class="shopping-cart-input">
                                                            <p class="d-inline-block mr-2">Inactive time (hours) for shopping cart to considered abandoned:</p> 
                                                            <vs-input v-model="inactiveTimeToAbandoned" name="SHoppingCartNumber" class="d-inline-block"/>
                                                        </div>
                                                        <vs-button type="filled" class="mr-4 mt-4 mb-2" color="primary" @click="saveEcommerceSettings()">Save Settings</vs-button>
                                                    </div>
                                                </div>
                                                <hr/>
                                                <div class="vx-row">
                                                    <div class="vx-col w-full mt-6">
                                                        <h5>Shopify Instructions</h5>
                                                    </div>
                                                </div>
                                            </template>
                                        </div>
                                    </div>
                                </form>
                            </b-card-text>
                        </b-tab>
                        <b-tab title="Cookie Consent" :disabled="!isCookieConsentTab" v-if="cookieConsentVisible">
                        <!-- <b-tab title="Cookie Consent" :disabled="!isCookieConsentTab || cookieConsentReadOnly" v-if="cookieConsentVisible"> -->
                            <b-card-text>
                                <form data-vv-scope="cookieConsentForm">
                                    <div class="tab-text">
                                        <vs-collapse accordion class="px-0">
                                            <!-- Company Information -->
                                            <div class="filter-card">
                                                <vs-collapse-item>
                                                    <div slot="header" class="d-flex align-items-center">
                                                        <span class="mr-2">Required Company Information </span>
                                                        <vx-tooltip :text="infoToolTipText" color="primary" >
                                                            <div class="text-primary">
                                                                <feather-icon icon="InfoIcon"></feather-icon>
                                                            </div>
                                                        </vx-tooltip>
                                                    </div>
                                                    <div class="filter-list">
                                                        <div class="vx-row">
                                                            <div class="vx-col w-full mb-6 md:w-1/2">
                                                                <vs-input class="w-full" label="Company Name" placeholder="Enter Company Name" v-model="companyName" name="companyName" v-validate="'required'"/>
                                                                <span class="text-danger text-sm">{{errors.first("cookieConsentForm.companyName")}}</span>
                                                            </div>
                                                            <div class="vx-col w-full mb-6 md:w-1/2">
                                                                <vs-input class="w-full" label="Company Domain" placeholder="Enter Company Domain" v-model="companyDomain" name="companyDomain" v-validate="'required'"/>
                                                                <span class="text-danger text-sm">{{errors.first("cookieConsentForm.companyDomain")}}</span>
                                                            </div>
                                                        </div>
                                                        <div class="vx-row">
                                                            <div class="vx-col w-full mb-6 md:w-1/2">
                                                                <vs-input class="w-full" label="Company Address" placeholder="Enter Company Address" v-model="companyAddress" name="companyAddress" v-validate="'required'"/>
                                                                <span class="text-danger text-sm">{{errors.first("cookieConsentForm.companyAddress")}}</span>
                                                            </div>
                                                            <div class="vx-col w-full mb-6 md:w-1/2">
                                                                <vs-input class="w-full" label="Company Address2" placeholder="Enter Company Address2" v-model="companyAddress2"/>
                                                            </div>
                                                        </div>
                                                        <div class="vx-row">
                                                            <div class="vx-col w-full mb-6 md:w-1/2">
                                                                <vs-input class="w-full" label="Company City" placeholder="Enter Company City" v-model="companyCity" name="companyCity" v-validate="'required'"/>
                                                                <span class="text-danger text-sm">{{errors.first("cookieConsentForm.companyCity")}}</span>
                                                            </div>
                                                            <div class="vx-col w-full mb-6 md:w-1/2">
                                                                <vs-input class="w-full" label="Company State" placeholder="Enter Company State" v-model="companyState" name="companyState" v-validate="'required'"/>
                                                                <span class="text-danger text-sm">{{errors.first("cookieConsentForm.companyState")}}</span>
                                                            </div>
                                                        </div>
                                                        <div class="vx-row">
                                                            <div class="vx-col w-full mb-6 md:w-1/2">
                                                                <vs-input class="w-full" label="Company Zipcode" placeholder="Enter Company Zipcode" v-model="companyZipcode" name="companyZipcode" v-validate="'required'"/>
                                                                <span class="text-danger text-sm">{{errors.first("cookieConsentForm.companyZipcode")}}</span>
                                                            </div>
                                                            <div class="vx-col w-full mb-6 md:w-1/2">
                                                                <vs-input class="w-full" label="Company Phone" placeholder="Enter Company Phone" v-model="companyPhone" name="companyPhone" v-validate="'required'"/>
                                                                <span class="text-danger text-sm">{{errors.first("cookieConsentForm.companyPhone")}}</span>
                                                            </div>
                                                        </div>
                                                        <div class="vx-row">
                                                            <div class="vx-col w-full mb-6 md:w-1/2">
                                                                <vs-input class="w-full" label="Company Support Email" placeholder="Enter Company Email Address" v-model="companySupportEmail" name="companySupportEmail" v-validate="'required|email'"/>
                                                                <span class="text-danger text-sm">{{errors.first("cookieConsentForm.companySupportEmail")}}</span>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </vs-collapse-item>
                                            </div>
                                            <!-- Position -->
                                            <div class="filter-card">
                                                <vs-collapse-item>
                                                    <div slot="header">Position</div>
                                                    <div class="filter-list">
                                                        <ul>
                                                            <li v-for="i in positionList" :key="i.Key">
                                                                <vs-radio v-model="cookiePosition" vs-name="position" :vs-value="i.Key"  class="mb-4">{{ i.Value }}</vs-radio>
                                                            </li>
                                                        </ul>
                                                    </div>
                                                </vs-collapse-item>
                                            </div>
                                            <!-- Theme -->
                                            <div class="filter-card">
                                                <vs-collapse-item>
                                                    <div slot="header">Theme</div>
                                                    <div class="filter-list">
                                                        <ul>
                                                            <li v-for="i in themeList" :key="i.Key">
                                                                <vs-radio v-model="cookieTheme" vs-name="theme" :vs-value="i.Key" class="mb-4">{{ i.Value }}</vs-radio>
                                                            </li>
                                                        </ul>  
                                                    </div>
                                                </vs-collapse-item>
                                            </div>
                                            <!-- Color -->
                                            <div class="filter-card" v-if="cookieOptions">
                                                <vs-collapse-item>
                                                    <div slot="header">Color</div>
                                                    <div class="filter-list">
                                                        <!-- Banner -->
                                                        <div class="color-picker-custom">
                                                            <div class="first-section-picker">
                                                                {{ cookieOptions.Cookie_Palette_Popup_Background == cookiePalettePopupBackground || bannerOptions.Cookie_Palette_Popup_Background == cookiePalettePopupBackground 
                                                                ? "# Banner" : cookiePalettePopupBackground }}
                                                            </div>
                                                            <div class="color-section-picker">
                                                                <span class="material-icons"> palette </span>
                                                                <vs-input type="color" v-model="cookiePalettePopupBackground" />
                                                            </div>
                                                        </div>
                                                        <!-- Banner text -->
                                                        <div class="color-picker-custom">
                                                            <div class="first-section-picker">
                                                                {{ cookieOptions.Cookie_Palette_Popup_Text == cookiePalettePopupText || bannerOptions.Cookie_Palette_Popup_Text == cookiePalettePopupText 
                                                                ? "# Banner text" : cookiePalettePopupText }}
                                                            </div>
                                                            <div class="color-section-picker">
                                                                <span class="material-icons"> palette </span>
                                                                <vs-input type="color" v-model="cookiePalettePopupText" />
                                                            </div>
                                                        </div>
                                                        <!-- Button -->
                                                        <div class="color-picker-custom">
                                                            <div class="first-section-picker">
                                                                {{ cookieOptions.Cookie_Palette_Button_Background == cookiePaletteButtonBackground || bannerOptions.Cookie_Palette_Button_Background == cookiePaletteButtonBackground 
                                                                ? "# Button" : cookiePaletteButtonBackground }}
                                                            </div>
                                                            <div class="color-section-picker">
                                                                <span class="material-icons"> palette </span>
                                                                <vs-input type="color" v-model="cookiePaletteButtonBackground" />
                                                            </div>
                                                        </div>
                                                        <!-- Button text -->
                                                        <div class="color-picker-custom">
                                                            <div class="first-section-picker">
                                                                {{ cookieOptions.Cookie_Palette_Button_Text == cookiePaletteButtonText || bannerOptions.Cookie_Palette_Button_Text == cookiePaletteButtonText 
                                                                ? "# Button text" : cookiePaletteButtonText }}
                                                            </div>
                                                            <div class="color-section-picker">
                                                                <span class="material-icons"> palette </span>
                                                                <vs-input type="color" v-model="cookiePaletteButtonText" />
                                                            </div>
                                                        </div>
                                                    </div>
                                                </vs-collapse-item>
                                            </div>
                                            <!-- Link to cookie policy -->
                                            <div class="filter-card">
                                                <vs-collapse-item>
                                                    <div slot="header">Link to Privacy Policy</div>
                                                    <div class="filter-list">
                                                        <ul ref="radioRef">
                                                            <li><vs-radio v-model="linkPolicy" vs-value="vvPolicy" vs-name="vvPolicy" class="mb-4" ref="defaultPolicyRef">Use our generic privacy policy</vs-radio></li>
                                                            <li>
                                                                <vs-radio v-model="linkPolicy" vs-value="ownPolicy" vs-name="ownPolicy" class="mb-4">Use your privacy policy</vs-radio>
                                                                <vs-input 
                                                                    v-if="linkPolicy=='ownPolicy'" 
                                                                    v-model="cookiePolicyLink" class="mb-2 w-full" 
                                                                    placeholder="https://www.example.com/cookiepolicy" 
                                                                    :disabled="linkPolicy!='ownPolicy'" 
                                                                    name="linkPolicy"
                                                                    v-validate="linkPolicy=='ownPolicy' ? 'required|url' : ''" 
                                                                />
                                                                <span v-if="linkPolicy=='ownPolicy'" class="text-danger text-sm">{{errors.first("cookieConsentForm.linkPolicy")}}</span>                                                                
                                                                <vs-input v-else class="mb-2 w-full" placeholder="https://www.example.com/cookiepolicy" :disabled="linkPolicy!='ownPolicy'" />
                                                            </li>
                                                        </ul>
                                                    </div>
                                                </vs-collapse-item>
                                            </div>
                                            <!-- Custom Text -->
                                            <div class="filter-card">
                                                <vs-collapse-item>
                                                    <div slot="header">Custom Text</div>
                                                    <div class="filter-list">
                                                        <vs-checkbox v-model="cookieUseCustomTextBanner" @click="cookieCustomTextBanner=cookieOptions.Cookie_Custom_Text_Banner.replace('[Demo]', '').trim()">Banner message</vs-checkbox>
                                                        <div class="m-3" v-if="cookieUseCustomTextBanner">
                                                            <b-form-textarea id="textarea" v-model="cookieCustomTextBanner" class="w-full" placeholder="This website uses cookies to ensure you get the best experience on our website."></b-form-textarea>
                                                        </div>
                                                        <vs-checkbox class="mt-2" v-model="cookieUseCustomTextPolicyLabel" @click="cookieCustomTextPolicyLabel=cookieOptions.Cookie_Custom_Text_Policy_Label">Privacy policy display text</vs-checkbox>
                                                        <div v-if="cookieUseCustomTextPolicyLabel" class="m-3">
                                                            <vs-input v-model="cookieCustomTextPolicyLabel" class="mb-2" placeholder="Learn more" />
                                                        </div>
                                                        <vs-checkbox class="mt-2" v-model="cookieUseCustomTextAcceptButtonText" @click="cookieCustomTextAcceptButtonText=cookieOptions.Cookie_Custom_Text_Accept_Button_Text">Accept button text</vs-checkbox>
                                                        <div class="m-3" v-if="cookieUseCustomTextAcceptButtonText">
                                                            <vs-input v-model="cookieCustomTextAcceptButtonText" class="mb-2" placeholder="Allow cookies" />
                                                        </div>
                                                        <vs-checkbox class="mt-2" v-model="cookieUseCustomTextDismissButtonText" @click="cookieCustomTextDismissButtonText=cookieOptions.Cookie_Custom_Text_Dismiss_Button_Text">Dismiss button text</vs-checkbox>
                                                        <div class="m-3" v-if="cookieUseCustomTextDismissButtonText">
                                                            <vs-input v-model="cookieCustomTextDismissButtonText" class="mb-2" placeholder="Decline" />
                                                        </div>
                                                    </div>
                                                </vs-collapse-item>
                                            </div>
                                        </vs-collapse>
                                    </div>
                                    <div class="vx-row">
                                        <div class="vx-col w-full mt-6">
                                            <vs-button type="filled" class="mr-4 mb-2" color="primary" @click="validateSaveCookieConsentChange()">{{ LabelConstant.buttonTitleSaveChanges }}</vs-button>
                                            <vs-button color="grey" type="border" class="mb-2 grey-btn" @click="resetSecondTab()">{{ LabelConstant.buttonLabelReset }}</vs-button>
                                        </div>
                                    </div>
                                </form>
                            </b-card-text>
                        </b-tab>
                    </b-tabs>
                    <!-- Cookie Consent Modal -->
                    <cookie-modal
                        :isCookieConsentModel="isShowCookieConsentModel && isCookieConsentModel"
                        :cookiePosition="cookiePosition"
                        :cookieTheme="cookieTheme"
                        :cookiePalettePopupBackground="cookiePalettePopupBackground"
                        :cookiePalettePopupText="cookiePalettePopupText"
                        :cookiePaletteButtonBackground="cookiePaletteButtonBackground" 
                        :cookiePaletteButtonText="cookiePaletteButtonText"
                        :linkPolicy="linkPolicy"
                        :cookieUseDefaultPolicy="cookieUseDefaultPolicy"
                        :cookiePolicyLink="cookiePolicyLink"
                        :cookieCustomTextBanner="cookieCustomTextBanner"
                        :cookieCustomTextPolicyLabel="cookieCustomTextPolicyLabel"
                        :cookieCustomTextAcceptButtonText="cookieCustomTextAcceptButtonText"
                        :cookieCustomTextDismissButtonText="cookieCustomTextDismissButtonText"
                        @closeModel="(newValue)=>{ isCookieConsentModel=newValue }"
                    ></cookie-modal>
                    <vs-button v-if="isShowCookieConsentModel && !isCookieConsentModel" @click="isCookieConsentModel=true" class="m-0 modal-floating bottom-right" type="filled" :style="{'background-color':cookiePalettePopupBackground +'!important'}">
                        <span :style="{'color':cookiePalettePopupText}">{{cookieCustomTextAcceptButtonText}}</span>
                    </vs-button>
                </vx-card>
            </div>
        </div>
    </div>

    <!-- Help popup -->
    <help-modal :popupActive="popupActive" @close="closepopup"></help-modal>

    <!-- if Add Cookie Consent remove -->
    <vs-popup title="Cookie Consent" @close="cookieConsentPopup" :active.sync="cookieConsentPopup">
        <template>
            <div class="vx-row mb-3">
                <div class="vx-col w-full text-left">
                    <p>You have opted not to include the cookie consent banner to the script. 
                        An email will be sent to your mailbox containing an approval link. Once you have clicked on this link, the banner will be disabled.</p>
                </div>
            </div>
        </template>
        <div class="text-right mt-6 mb-8">
            <vs-button color="primary" type="filled" class="mr-2" @click="agreeCookieConsent()">{{ LabelConstant.buttonLabelOk }}</vs-button>
            <vs-button color="grey" type="border" class="grey-btn mr-0" @click="cancelCookieConsent()">{{ LabelConstant.buttonLabelCancel }}</vs-button>
        </div>
    </vs-popup>

    <!-- Cookie Consent take time alert pop-up -->
    <vs-popup class="transaction-popup" :active.sync="saveCookieConsentChangePopup">
        <template>
            <div class="vx-row mb-3">
                <div class="vx-col w-full text-left">
                    <p>Changes made to your Cookie Consent can take up to 5 minutes to reflect on the website. </p>
                </div>
            </div>
        </template>
        <div class="text-center mt-6 mb-3">
            <vs-button @click="saveCookieConsentChangePopup=false" color="primary"  type="filled"> {{ LabelConstant.buttonLabelClose }} </vs-button>
        </div>
    </vs-popup>

    <!-- Pop up for validating when save the changes -->
    <vs-popup class="transaction-popup" :active.sync="validateFieldsPopup">
        <template>
            <div class="vx-row mb-3">
                <div class="vx-col w-full text-center">
                    <p>Required information is missing.</p>
                </div>
            </div>
        </template>
        <div class="text-center mt-6 mb-3">
            <vs-button color="primary" type="filled" class="mr-2" @click="validateFields()">{{ LabelConstant.buttonLabelOk }}</vs-button>
        </div>
    </vs-popup>
</div>
</template>

<script>
import VxCard from "@/components/vx-card/VxCard";
import HelpModal from "../../components/modal/HelpModal";
import { Validator } from "vee-validate";
import CookieModal from '../../components/configuration/CookieModal.vue';

const dict = {
  custom: {
    enterEmail: {
      required: "Please enter email",
      email: "Please enter valid email",
    },
    companyName: {
      required: "Please enter company name"
    },
    companyDomain : {
      required: "Please enter company domain"
    },
    companyAddress : {
      required: "Please enter company address"
    },
    companyCity : {
      required: "Please enter company city"
    },
    companyState : {
      required: "Please enter company state"
    },
    companyZipcode : {
      required: "Please enter company zipcode"
    },
    companyPhone : {
      required: "Please enter company phone"
    },
    companySupportEmail : {
      required: "Please enter company support email",
      email: "Please enter a valid email address."
    },
    linkPolicy:{
      required: "Please enter Privacy Policy",
      url: 'Please enter a valid url.'
    },
  },
};
Validator.localize("en", dict);
export default {
    components: {
        VxCard,
        HelpModal,
        CookieModal
    },
    data() {
        return {
            tabIndex:0,
            isConsumerPlan:false,
            
            //Instructions Tab
            installationCode: null,

            cookieConsentReadOnly:false,
            isUntrustedReseller: false,

            plusEmployeeEnabled:false,
            plusEmployeeVisible:false,
            cookieConsentEnabled:false,
            cookieConsentVisible:false,
            requestToDisableConsent:false,

            enterEmail: null,
            txtMsg: null,

            getInfo : null,
            userEmail:null,
        
            //Cookie Consent Tab
            cookieConsentPopup: false,
            isCookieConsentTab: false,
            isCookieConsentModel:false,
            isShowCookieConsentModel:false,

            positionList:[],
            themeList:[],

            cookieConsentResponse:null,
            cookieOptions:{},

            infoToolTipText:null,

            companyName :null,
            companyDomain :null,
            companyAddress :null,
            companyAddress2 :null,
            companyCity :null,
            companyState :null,
            companyZipcode :null,
            companyPhone :null,
            companySupportEmail :null,

            cookiePosition : 'bottom',
            cookieTheme : 'block',

            cookiePalettePopupBackground :null,
            cookiePalettePopupText :null,
            cookiePaletteButtonBackground :null, 
            cookiePaletteButtonText :null,
            
            linkPolicy: null,
            cookieUseDefaultPolicy  :null,
            cookiePolicyLink : null,

            cookieUseCustomTextBanner :null,
            cookieCustomTextBanner:'This website uses cookies to ensure you get the best experience on our website.',
            cookieUseCustomTextPolicyLabel :false,
            cookieCustomTextPolicyLabel :'Learn more',
            cookieUseCustomTextAcceptButtonText :false,
            cookieCustomTextAcceptButtonText :'Allow cookies',
            cookieUseCustomTextDismissButtonText :false,
            cookieCustomTextDismissButtonText : 'Decline',
            isReseller: false,

            popupActive: false,
            themeDetail:null,
            bannerOptions:{},

            saveCookieConsentChangePopup:false,
            validateFieldsPopup:false,
            vendastaIsPartner: false,

            //Shopping Cart
            isShoppingCartShow: false,
            inactiveTimeToAbandoned: null,
        };
    },
    created(){
        let uid = this.$route.params.id;
        let VVtoken = localStorage.getItem("VVtoken");
        let token = JSON.parse(VVtoken);
        this.isConsumerPlan=token[uid].isConsumerPlan
        this.themeDetail=token[uid].themeDetail;
        this.isReseller = token[uid].isReseller
        this.vendastaIsPartner=token[uid].vendastaIsPartner

        // reset second tab
        this.bannerOptions.Cookie_Position = "bottom";
        this.bannerOptions.Cookie_Theme = "block";
        this.bannerOptions.Cookie_Palette_Popup_Background = this.RGBToHex(this.themeDetail.ThemeColor);
        this.bannerOptions.Cookie_Palette_Popup_Text = this.RGBToHex(this.themeDetail.NavbarColor);
        this.bannerOptions.Cookie_Palette_Button_Background = this.RGBToHex(this.themeDetail.ActionButtonColor);
        this.bannerOptions.Cookie_Palette_Button_Text = this.RGBToHex(this.themeDetail.NavbarColor);
        this.bannerOptions.Cookie_Use_Default_Policy = true;
        this.bannerOptions.Cookie_Policy_Link = null;
        this.bannerOptions.Cookie_Use_Custom_Text_Banner = false;
        this.bannerOptions.Cookie_Custom_Text_Banner = "This website uses cookies to ensure you get the best experience on our website.";
        this.bannerOptions.Cookie_Use_Custom_Text_Policy_Label = false;
        this.bannerOptions.Cookie_Custom_Text_Policy_Label = "Learn more";
        this.bannerOptions.Cookie_Use_Custom_Text_Accept_Button_Text = false;
        this.bannerOptions.Cookie_Custom_Text_Accept_Button_Text = "Allow cookies";
        this.bannerOptions.Cookie_Use_Custom_Text_Dismiss_Button_Text = false;
        this.bannerOptions.Cookie_Custom_Text_Dismiss_Button_Text = "Decline";
    },
    mounted() {
        this.getInstallationCookieOptionLists();
        this.getInstallationWithConsent();
        this.getMyInfo();
        this.getEcommerceSettings();

        // After Sending a email check Cookie Consent is read-only, page auto update
        window.setInterval(() => {
            if(this.cookieConsentReadOnly && !this.isUntrustedReseller){
                this.axios.get("/ws/ConfigInstallation/GetInstallationWithConsent").then((response) => {
                    let data = response.data;
                    this.cookieConsentResponse = data;
                    this.cookieOptions = data.Cookie_Options;

                    this.setFirstTab();
                })
                .catch((e) => {
                    this.showError(e);
                });
            }
        }, 1000*60);
        window.addEventListener('storage', (event) => {
            if (event.storageArea != localStorage) return;
            if (event.key === 'isCookieConsent' && localStorage.getItem("isCookieConsent")) {
                let isCookieConsentStr = localStorage.getItem("isCookieConsent")
                let isCookieConsentJson = JSON.parse(isCookieConsentStr);
                if(isCookieConsentJson.isCalled){
                    this.axios.get("/ws/ConfigInstallation/GetInstallationWithConsent").then((response) => {
                        let data = response.data;
                        this.cookieConsentResponse = data;
                        this.cookieOptions = data.Cookie_Options;
                        this.setFirstTab();
                    })
                    .catch((e) => {
                        this.showError(e);
                    });
                    localStorage.removeItem('isCookieConsent')
                }
            }
        });
    },
    methods: {
        // Call webservice /ws/MyInfo/
        async getMyInfo(){
            this.$vs.loading();
            await this.axios.get("/ws/MyInfo/GetMyInfo")
                .then((response) => {
                    let data = response.data;
                    this.getInfo= data;
                    this.userEmail=this.getInfo.Email
                    this.$vs.loading.close();
                })
                .catch((e) => {
                    this.$vs.loading.close();
                    this.showError(e);
                });
        },
        async getInstallationCookieOptionLists(){
            this.$vs.loading();
            await this.axios.get("/ws/ConfigInstallation/GetInstallationCookieOptionLists")
                .then((response) => {
                    let data = response.data;
                    this.positionList=data.PositionList;
                    this.themeList=data.ThemeList;
                    this.$vs.loading.close();
                })
                .catch((e) => {
                    this.$vs.loading.close();
                    this.showError(e);
                });
        },
        async getInstallationWithConsent(){
            this.$vs.loading();
            await this.axios.get("/ws/ConfigInstallation/GetInstallationWithConsent")
                .then((response) => {
                    let data = response.data;
                    this.cookieConsentResponse = data;
                    this.cookieOptions = data.Cookie_Options;
                    this.isShoppingCartShow = data.ECommerce_Visible;

                    this.resetFirstTab();
                    this.setSecondTab();
                    
                    this.$vs.loading.close();
                })
                .catch((e) => {
                    this.$vs.loading.close();
                    this.showError(e);
                });
        },  
        // Set data for first tab
        setFirstTab(){
            this.isUntrustedReseller = this.cookieConsentResponse.Lock_Cookie_Consent;
            this.cookieConsentReadOnly=this.cookieConsentResponse.Cookie_Consent_ReadOnly;
            this.requestToDisableConsent = this.cookieConsentResponse.Request_To_Disable_Consent

            this.installationCode = this.cookieConsentResponse.Installation_Code;
            this.plusEmployeeVisible = this.cookieConsentResponse.PlusEmployee_Visible;
            this.plusEmployeeEnabled = this.plusEmployeeVisible ? this.cookieConsentResponse.PlusEmployee_Enabled : false;
            
            if(this.isConsumerPlan){
                this.cookieConsentVisible = this.cookieConsentResponse.Cookie_Consent_Visible;
                this.cookieConsentEnabled = this.cookieConsentVisible ? this.cookieConsentResponse.Cookie_Consent_Enabled : false;
            }else{
                if(this.plusEmployeeVisible && this.plusEmployeeEnabled){
                    this.cookieConsentVisible = this.cookieConsentResponse.Cookie_Consent_Visible;
                    this.cookieConsentEnabled = this.cookieConsentVisible ? this.cookieConsentResponse.Cookie_Consent_Enabled : false;
                }else{
                    this.cookieConsentVisible = false;
                    this.cookieConsentEnabled = false;
                }
            }

            if(this.cookieConsentVisible || this.cookieConsentEnabled){
                this.isCookieConsentModel=true;
                this.isCookieConsentTab=true;
            }else{
                this.isCookieConsentModel=false;
                this.isCookieConsentTab=false;
            }

            if(this.cookieConsentVisible && this.cookieConsentEnabled){
            //if(!this.cookieConsentReadOnly && this.cookieConsentVisible && this.cookieConsentEnabled){
                this.isShowCookieConsentModel=true;
            }else{
                this.isShowCookieConsentModel=false;
            }
        },
        // Set data for second tab
        setSecondTab(){
            this.companyName= this.cookieConsentResponse.Cookie_Consent_Company_Name
            this.companyDomain= this.cookieConsentResponse.Cookie_Consent_Company_Domain
            this.companyAddress= this.cookieConsentResponse.Cookie_Consent_Company_Address != 'missing_info' ? this.cookieConsentResponse.Cookie_Consent_Company_Address : null
            this.companyAddress2= this.cookieConsentResponse.Cookie_Consent_Company_Address2
            this.companyCity= this.cookieConsentResponse.Cookie_Consent_Company_City != 'missing_info' ? this.cookieConsentResponse.Cookie_Consent_Company_City : null
            this.companyState= this.cookieConsentResponse.Cookie_Consent_Company_State != 'missing_info' ? this.cookieConsentResponse.Cookie_Consent_Company_State : null
            this.companyZipcode= this.cookieConsentResponse.Cookie_Consent_Company_Zipcode != 'missing_info' ? this.cookieConsentResponse.Cookie_Consent_Company_Zipcode : null
            this.companyPhone= this.cookieConsentResponse.Cookie_Consent_Company_Phone != 'missing_info' ? this.cookieConsentResponse.Cookie_Consent_Company_Phone : null
            this.companySupportEmail= this.cookieConsentResponse.Cookie_Consent_Company_Support_Email != 'missing_info' ? this.cookieConsentResponse.Cookie_Consent_Company_Support_Email : null
            this.infoToolTipText= this.cookieConsentResponse.Required_Company_Info_Tool_Tip_Text

            this.cookiePosition = this.cookieOptions.Cookie_Position;
            this.cookieTheme = this.cookieOptions.Cookie_Theme;

            if(!this.isReseller || this.vendastaIsPartner || this.isConsumerPlan){
                this.cookiePalettePopupBackground = this.cookieOptions.Cookie_Palette_Popup_Background;
                this.cookiePalettePopupText = this.cookieOptions.Cookie_Palette_Popup_Text;
                this.cookiePaletteButtonBackground = this.cookieOptions.Cookie_Palette_Button_Background; 
                this.cookiePaletteButtonText = this.cookieOptions.Cookie_Palette_Button_Text; 
            }else{
                this.cookiePalettePopupBackground = this.RGBToHex(this.themeDetail.ThemeColor);
                this.cookiePalettePopupText = this.RGBToHex(this.themeDetail.NavbarColor);
                this.cookiePaletteButtonBackground = this.RGBToHex(this.themeDetail.ActionButtonColor);
                this.cookiePaletteButtonText = this.RGBToHex(this.themeDetail.NavbarColor);
            }

            this.cookieUseDefaultPolicy  = this.cookieOptions.Cookie_Use_Default_Policy;
            this.linkPolicy = this.cookieUseDefaultPolicy ? "vvPolicy" : "ownPolicy";
            this.cookiePolicyLink = this.cookieOptions.Cookie_Policy_Link;
            
            this.cookieUseCustomTextBanner = this.cookieOptions.Cookie_Use_Custom_Text_Banner;
            this.cookieUseCustomTextPolicyLabel = this.cookieOptions.Cookie_Use_Custom_Text_Policy_Label;
            this.cookieUseCustomTextAcceptButtonText = this.cookieOptions.Cookie_Use_Custom_Text_Accept_Button_Text;
            this.cookieUseCustomTextDismissButtonText = this.cookieOptions.Cookie_Use_Custom_Text_Dismiss_Button_Text;
            this.cookieCustomTextBanner = this.cookieOptions.Cookie_Custom_Text_Banner.replace("[Demo]", "").trim();
            this.cookieCustomTextPolicyLabel = this.cookieOptions.Cookie_Custom_Text_Policy_Label;
            this.cookieCustomTextAcceptButtonText = this.cookieOptions.Cookie_Custom_Text_Accept_Button_Text;
            this.cookieCustomTextDismissButtonText = this.cookieOptions.Cookie_Custom_Text_Dismiss_Button_Text;
            
            
            if(this.cookieConsentVisible && this.cookieConsentEnabled){
                setTimeout(() => {
                    this.$validator.validateAll("cookieConsentForm").then(async (result) => {
                        if (result) {
                            this.validateFieldsPopup=false;
                        }else{
                            this.validateFieldsPopup=true; 
                        }
                    });
                }, 200);
            }
        }, 
        // changes in Enable + Employee checkbox
        changePlusEmployee(){
            if(!this.isConsumerPlan){
                if(this.plusEmployeeEnabled){
                    this.cookieConsentVisible = true;
                    this.cookieConsentEnabled = true;
                    // if(this.cookieConsentReadOnly && !this.isCookieConsentModel){
                    if(!this.isCookieConsentModel){    
                        this.isShowCookieConsentModel=false;
                        this.isCookieConsentTab=false;
                    }else{
                        this.isShowCookieConsentModel=true;
                        this.isCookieConsentTab=true;
                    }
                    this.isCookieConsentModel=true;
                    setTimeout(() => {
                        this.$validator.validateAll("cookieConsentForm").then(async (result) => {
                            if (result) {
                                this.saveInstallationWithConsent();
                            }else{
                                this.isShowCookieConsentModel=false;  
                                this.validateFieldsPopup=true; 
                            }
                        });
                    }, 200);            
                }
                else{
                    this.cookieConsentVisible = false;
                    this.cookieConsentEnabled = false;
                    this.isShowCookieConsentModel=false;
                    this.isCookieConsentModel=false;
                    this.isCookieConsentTab=false;
                    this.saveInstallationWithConsent();
                }
            }else{
                if(this.plusEmployeeEnabled){
                    setTimeout(() => {
                        this.$validator.validateAll("cookieConsentForm").then(async (result) => {
                            if (result) {
                                this.saveInstallationWithConsent();
                            }else{
                                this.isShowCookieConsentModel=false;  
                                this.validateFieldsPopup=true; 
                            }
                        });
                    }, 200);
                }else{
                    this.saveInstallationWithConsent();
                }
            }
        },
        // Click on Ok button For Not filling fields warning pop up
        validateFields(){
            this.validateFieldsPopup=false;
            this.tabIndex=1;
            // this.cookieConsentEnabled = !this.isConsumerPlan?false:this.cookieConsentEnabled;
            this.cookieConsentEnabled = this.cookieConsentEnabled;

            let collapse = document.getElementsByClassName("vs-collapse-item");
            if(this.errors.items[0].field.search("company")!=-1){
                collapse[0].classList.add("open-item"); 
            }else{
                collapse[4].classList.add("open-item"); 
            }         
        },
        // change in Cookie Consent checkbox
        changeCookieConsent(){
            if(this.cookieConsentEnabled){
                this.isShowCookieConsentModel=true;
                this.isCookieConsentModel=true;
                this.isCookieConsentTab=true;
                setTimeout(() => {
                    this.$validator.validateAll("cookieConsentForm").then(async (result) => {
                        if (result) {
                            this.isShowCookieConsentModel=true;
                            this.isCookieConsentModel=true;
                            this.isCookieConsentTab=true;
                            this.saveInstallationWithConsent();
                        }else{
                            this.validateFieldsPopup=true; 
                        }
                    });
                }, 200);
            }else{
                this.isShowCookieConsentModel=false;
                this.cookieConsentPopup=true;
            }
        },
        // I agree in cookieconsent popup
        agreeCookieConsent(){
            this.cookieConsentPopup=false;
            // this.saveInstallationWithConsent(); 
            this.$vs.loading();
            this.cookieUseDefaultPolicy = this.linkPolicy == "vvPolicy" ? true : false;
            let input = {
                PlusEmployee_Visible: this.plusEmployeeVisible,
                PlusEmployee_Enabled: this.plusEmployeeEnabled,
                Cookie_Consent_Visible: this.cookieConsentVisible,
                Cookie_Consent_Enabled: true,
                Request_To_Disable_Consent: true,
                Cookie_Options: {
                    Cookie_Position: this.cookiePosition,
                    Cookie_Theme: this.cookieTheme,
                    Cookie_Palette_Popup_Background: this.cookiePalettePopupBackground,
                    Cookie_Palette_Popup_Text: this.cookiePalettePopupText,
                    Cookie_Palette_Button_Background: this.cookiePaletteButtonBackground,
                    Cookie_Palette_Button_Text: this.cookiePaletteButtonText,
                    Cookie_Use_Default_Policy: this.cookieUseDefaultPolicy,
                    Cookie_Policy_Link: this.cookieUseDefaultPolicy ? "" : this.cookiePolicyLink,
                    Cookie_Use_Custom_Text_Banner: this.cookieUseCustomTextBanner,
                    Cookie_Custom_Text_Banner: this.cookieCustomTextBanner,
                    Cookie_Use_Custom_Text_Policy_Label: this.cookieUseCustomTextPolicyLabel,
                    Cookie_Custom_Text_Policy_Label: this.cookieCustomTextPolicyLabel,
                    Cookie_Use_Custom_Text_Accept_Button_Text: this.cookieUseCustomTextAcceptButtonText,
                    Cookie_Custom_Text_Accept_Button_Text: this.cookieCustomTextAcceptButtonText,
                    Cookie_Use_Custom_Text_Dismiss_Button_Text: this.cookieUseCustomTextDismissButtonText,
                    Cookie_Custom_Text_Dismiss_Button_Text: this.cookieCustomTextDismissButtonText
                },
                Cookie_Consent_Company_Name: this.companyName,
                Cookie_Consent_Company_Domain: this.companyDomain,
                Cookie_Consent_Company_Address: this.companyAddress,
                Cookie_Consent_Company_Address2: this.companyAddress2,
                Cookie_Consent_Company_City: this.companyCity,
                Cookie_Consent_Company_State: this.companyState,
                Cookie_Consent_Company_Zipcode: this.companyZipcode,
                Cookie_Consent_Company_Phone: this.companyPhone,
                Cookie_Consent_Company_Support_Email: this.companySupportEmail,
                Required_Company_Info_Tool_Tip_Text: "",
            }
            this.axios.post("/ws/ConfigInstallation/SaveInstallationWithConsent", input).then(() => {
                this.cookieConsentEnabled=true;
                this.isShowCookieConsentModel=false;
                this.isCookieConsentModel=false;
                this.isCookieConsentTab = true;
                this.cookieConsentReadOnly=true;
                this.requestToDisableConsent=true;

                this.$vs.notify({
                    title: "Success",
                    text: "Your information has been updated successfully.",
                    color: "success",
                    iconPack: "feather",
                    icon: "icon-check",
                    position: "top-right",
                    time: 4000,
                });
                setTimeout(() => {
                    this.errors.clear();
                }, 100);
                this.$vs.loading.close();
            })
            .catch((e) => {
                this.$vs.loading.close();
                this.showError(e);
                this.$vs.notify({
                    title: "Erorr",
                    text: e.response.data.Errors[0].Message,
                    color: "danger",
                    iconPack: "feather",
                    icon: "icon-check",
                    position: "top-right",
                    time: 4000,
                });
            });            
        },
        cancelCookieConsent(){
            this.cookieConsentPopup = false;
            this.cookieConsentEnabled=true;
            this.isShowCookieConsentModel=true;
            this.isCookieConsentModel=true;
            this.cookieConsentReadOnly=false;
            this.isCookieConsentTab=true;
        },
        // save change for second tab 
        validateSaveCookieConsentChange(){
            this.$validator.validateAll("cookieConsentForm").then(async (result) => {
                if (result) {
                    this.saveInstallationWithConsent();
                    this.saveCookieConsentChangePopup=true;
                }
            });
        },
        async saveInstallationWithConsent(){
            this.$vs.loading();
            this.cookieUseDefaultPolicy = this.linkPolicy == "vvPolicy" ? true : false;
            const url = this.companyDomain
            let domain
            if(url.includes('http://') || url.includes('https://')){ /* "http://www.abc.com/blog?id=123", "http://abc.com/blog?id=123" */
                domain = (new URL(url));
                domain = domain.hostname;
                domain = domain.replace('www.','');
            }else if(!url.includes('http://') && url.includes('.')){ /* "www.abc.com/blog?id=123", abc.com/blog?id=123 */
                domain = url;
                let protocol = "http://"
                let resultDomain = protocol.concat(domain);
                domain = (new URL(resultDomain));
                domain = domain.hostname;
                domain = domain.replace('www.','');
            }else{
                domain = url
            }
            let input = {
                PlusEmployee_Visible: this.plusEmployeeVisible,
                PlusEmployee_Enabled: this.plusEmployeeEnabled,
                Cookie_Consent_Visible: this.cookieConsentVisible,
                Cookie_Consent_Enabled: this.cookieConsentEnabled,
                Cookie_Options: {
                    Cookie_Position: this.cookiePosition,
                    Cookie_Theme: this.cookieTheme,
                    Cookie_Palette_Popup_Background: this.cookiePalettePopupBackground,
                    Cookie_Palette_Popup_Text: this.cookiePalettePopupText,
                    Cookie_Palette_Button_Background: this.cookiePaletteButtonBackground,
                    Cookie_Palette_Button_Text: this.cookiePaletteButtonText,
                    Cookie_Use_Default_Policy: this.cookieUseDefaultPolicy,
                    Cookie_Policy_Link: this.cookieUseDefaultPolicy ? "" : this.cookiePolicyLink,
                    Cookie_Use_Custom_Text_Banner: this.cookieUseCustomTextBanner,
                    Cookie_Custom_Text_Banner: this.cookieCustomTextBanner,
                    Cookie_Use_Custom_Text_Policy_Label: this.cookieUseCustomTextPolicyLabel,
                    Cookie_Custom_Text_Policy_Label: this.cookieCustomTextPolicyLabel,
                    Cookie_Use_Custom_Text_Accept_Button_Text: this.cookieUseCustomTextAcceptButtonText,
                    Cookie_Custom_Text_Accept_Button_Text: this.cookieCustomTextAcceptButtonText,
                    Cookie_Use_Custom_Text_Dismiss_Button_Text: this.cookieUseCustomTextDismissButtonText,
                    Cookie_Custom_Text_Dismiss_Button_Text: this.cookieCustomTextDismissButtonText
                },
                Cookie_Consent_Company_Name: this.companyName,
                Cookie_Consent_Company_Domain: domain,
                Cookie_Consent_Company_Address: this.companyAddress,
                Cookie_Consent_Company_Address2: this.companyAddress2,
                Cookie_Consent_Company_City: this.companyCity,
                Cookie_Consent_Company_State: this.companyState,
                Cookie_Consent_Company_Zipcode: this.companyZipcode,
                Cookie_Consent_Company_Phone: this.companyPhone,
                Cookie_Consent_Company_Support_Email: this.companySupportEmail,
                Required_Company_Info_Tool_Tip_Text: "",
            }

            await this.axios.post("/ws/ConfigInstallation/SaveInstallationWithConsent", input).then(() => {
                this.$vs.notify({
                    title: "Success",
                    text: "Your information has been updated successfully.",
                    color: "success",
                    iconPack: "feather",
                    icon: "icon-check",
                    position: "top-right",
                    time: 4000,
                });
                setTimeout(() => {
                    this.errors.clear();
                }, 100);
                this.$vs.loading.close();
            })
            .catch((e) => {
                this.$vs.loading.close();
                this.showError(e);
                this.$vs.notify({
                    title: "Erorr",
                    text: e.response.data.Errors[0].Message,
                    color: "danger",
                    iconPack: "feather",
                    icon: "icon-check",
                    position: "top-right",
                    time: 4000,
                });
            });
        },
        // send Installation Details for First tab 
        async checkValidation() {
            this.$validator.validateAll("senddetails").then(async (result) => {
                if (result) {
                    this.sendInstallationDetails();
                }
            });
        },
        async sendInstallationDetails() {
            this.$vs.loading();
            let input = {
                Email: this.enterEmail,
                Installation_Code: "",
                Message_Text: this.txtMsg,
            };
            await this.axios.post("/ws/ConfigInstallation/SendInstallationCode", input)
                .then(() => {
                    this.$vs.notify({
                        title: "Success",
                        text: "Your information has been updated successfully.",
                        color: "success",
                        iconPack: "feather",
                        icon: "icon-check",
                        position: "top-right",
                        time: 4000,
                    });
                    setTimeout(() => {
                        this.errors.clear();
                    }, 100);
                    this.getInstallationWithConsent();
                    this.$vs.loading.close();
                })
                .catch((e) => {
                    this.$vs.loading.close();
                    this.showError(e);
                    this.$vs.notify({
                        title: "Erorr",
                        text: e.response.data.Errors[0].Message,
                        color: "danger",
                        iconPack: "feather",
                        icon: "icon-check",
                        position: "top-right",
                        time: 4000,
                    });
                });
        },
        // copy text functionality
        copyText() {
            var copyText = document.getElementById("myInput");
            copyText.select();
            document.execCommand("copy");
        },
        closepopup() {
            this.popupActive = false;
        },
        // reset first tab 
        resetFirstTab(){
            this.setFirstTab();

            this.enterEmail= null;
            this.txtMsg= null;
            setTimeout(() => {
                this.errors.clear();
            }, 100);
        },
        // reset second tab 
        resetSecondTab(){
            // this.infoToolTipText = null;

            // this.companyName = null;
            // this.companyDomain = null;
            // this.companyAddress = null;
            // this.companyAddress2 = null;
            // this.companyCity = null;
            // this.companyState = null;
            // this.companyZipcode = null;
            // this.companyPhone = null;
            // this.companySupportEmail = null;

            this.cookiePosition = this.bannerOptions.Cookie_Position;
            this.cookieTheme = this.bannerOptions.Cookie_Theme;

            if(!this.isReseller || this.isConsumerPlan){
                this.cookiePalettePopupBackground = this.bannerOptions.Cookie_Palette_Popup_Background;
                this.cookiePalettePopupText = this.bannerOptions.Cookie_Palette_Popup_Text;
                this.cookiePaletteButtonBackground = this.bannerOptions.Cookie_Palette_Button_Background; 
                this.cookiePaletteButtonText = this.bannerOptions.Cookie_Palette_Button_Text; 
            }else{
                this.cookiePalettePopupBackground = this.RGBToHex(this.themeDetail.ThemeColor);
                this.cookiePalettePopupText = this.RGBToHex(this.themeDetail.NavbarColor);
                this.cookiePaletteButtonBackground = this.RGBToHex(this.themeDetail.ActionButtonColor); 
                this.cookiePaletteButtonText = this.RGBToHex(this.themeDetail.NavbarColor); 
            }

            this.cookieUseDefaultPolicy  = this.bannerOptions.Cookie_Use_Default_Policy;
            this.linkPolicy = this.cookieUseDefaultPolicy ? "vvPolicy" : "ownPolicy";
            this.cookiePolicyLink = this.bannerOptions.Cookie_Policy_Link;
            
            this.cookieUseCustomTextBanner = this.bannerOptions.Cookie_Use_Custom_Text_Banner;
            this.cookieUseCustomTextPolicyLabel = this.bannerOptions.Cookie_Use_Custom_Text_Policy_Label;
            this.cookieUseCustomTextAcceptButtonText = this.bannerOptions.Cookie_Use_Custom_Text_Accept_Button_Text;
            this.cookieUseCustomTextDismissButtonText = this.bannerOptions.Cookie_Use_Custom_Text_Dismiss_Button_Text;
            this.cookieCustomTextBanner = this.bannerOptions.Cookie_Custom_Text_Banner.replace("[Demo]", "").trim();
            this.cookieCustomTextPolicyLabel = this.bannerOptions.Cookie_Custom_Text_Policy_Label;
            this.cookieCustomTextAcceptButtonText = this.bannerOptions.Cookie_Custom_Text_Accept_Button_Text;
            this.cookieCustomTextDismissButtonText = this.bannerOptions.Cookie_Custom_Text_Dismiss_Button_Text; 
        },
        // Resed Email for disable cookie Consent
        async resendEmail(){
            this.$vs.loading();
            await this.axios.get("/ws/ConfigInstallation/SendUpdateConsentToCustomer")
                .then(() => {
                    this.$vs.notify({
                        title: "Success",
                        text: "Email is resend successfully.",
                        color: "success",
                        iconPack: "feather",
                        icon: "icon-check",
                        position: "top-right",
                        time: 4000,
                    });
                    this.$vs.loading.close();
                })
                .catch((e) => {
                    this.$vs.loading.close();
                    this.showError(e);
                });
        },
        // cancel disable cookie Consent Request
        async cancelRequest(){
            this.$vs.loading();
            await this.axios.get("/ws/ConfigInstallation/CancelDisableConsent")
                .then(() => {
                    this.$vs.notify({
                        title: "Success",
                        text: "Cancel request sent successfully.",
                        color: "success",
                        iconPack: "feather",
                        icon: "icon-check",
                        position: "top-right",
                        time: 4000,
                    });
                    this.cookieConsentReadOnly=false;
                    this.isCookieConsentModel=true;
                    this.isShowCookieConsentModel=true;
                    this.isCookieConsentTab=true;
                    this.$vs.loading.close();
                })
                .catch((e) => {
                    this.$vs.loading.close();
                    this.showError(e);
                });
        },
        //Get shopping cart settings details
        async getEcommerceSettings(){
            this.$vs.loading();
            await this.axios.get("/ws/ECommerceInstall/GetECommerceSettings")
                .then((response) => {
                    let data = response.data;
                    this.inactiveTimeToAbandoned = data.TimeToAbandon;
                })
                .catch((e) => {
                    this.$vs.loading.close();
                    this.showError(e);
                });
        },
        saveEcommerceSettings(){
            let payload = {
                TimeToAbandon: this.inactiveTimeToAbandoned
            }
            this.$vs.loading();
            this.axios.post("/ws/ECommerceInstall/SaveECommerceSettings", payload).then(() => {
                this.$vs.notify({
                    title: "Success",
                    text: "Shopping cart settings saved!",
                    color: "success",
                    iconPack: "feather",
                    icon: "icon-check",
                    position: "top-right",
                    time: 4000
                });
                this.$vs.loading.close();
            }).catch((e) => {
                this.$vs.loading.close();
                this.showError(e);
                this.$vs.notify({
                    title: "Error",
                    text: e.response.data.Errors[0].Message,
                    color: "danger",
                    iconPack: "feather",
                    icon: "icon-alert-circle",
                    position: "top-right",
                    time: 4000
                });
            });
        }
    },
};
</script>
